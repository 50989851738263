import './style.css'
import { BaseCheckbox, BaseRadio } from '@/components/index'
import swal from 'sweetalert2'

export default {
  data: () => ({
    form: {
      avatar: ''
    }
  }),
  methods: {
    async getData () {
      const id = this.$route.params.id || false
      if (id) {
        this.form = (await this.$http.get(`users/${id}`)).data.user
      }
    },
    async submit () {
      if (this.validate()) {
        const id = this.$route.params.id || false
        let status
        try {
          if (id) {
            status = (await this.$http.put(`users/${id}`, this.form)).status
          }
          if (!id) status = (await this.$http.post('users', this.form)).status
        } catch (e) {
          status = e.response.status
        }

        if (status === 200) {
          swal({
            title: 'Salvo!',
            text: 'Ingresso salvo com sucesso.',
            type: 'success',
            showConfirmButton: false,
            timer: 2000
          })
          this.goBack()
        } else {
          swal({
            title: 'Erro!',
            text: 'Ocorreu um erro, tente novamente mais tarde.',
            type: 'error',
            confirmButtonClass: 'btn btn-danger btn-fill',
            confirmButtonText: 'Ok',
            showConfirmButton: true
          })
        }
      } else {
        swal({
          title: 'Erro!',
          text: 'Por favor, preencha todos os campos obrigatórios.',
          type: 'error',
          confirmButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Ok',
          showConfirmButton: true
        })
      }
    },
    goBack () {
      window.history.go(-1)
    },

    money () {
      console.log(this.$helpers.formatMonetary(this.form.price))
    },

    addImage (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      const vue = this
      const file = files[0]
      var reader = new FileReader()

      reader.onload = e => {
        vue.form.avatar = e.target.result
      }
      reader.readAsDataURL(file)
      console.log(this.form)
    },

    validate () {
      const form = this.form
      if (!form.name || form.name === '') return false
      if (!form.role || form.role === '') return false
      if (!form.email || form.email === '') return false
      if (!form.id) {
        if (!form.password || form.password === '') return false
      }
      return true
    }
  },
  mounted () {
    this.getData()
  },
  components: {
    BaseCheckbox,
    BaseRadio
  }
}
